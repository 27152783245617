import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import './App.css';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Box, Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import Switch from '@material-ui/core/Switch';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 2,
    margin: 8,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: 'rgb(102, 187, 106)',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[800]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  tabHeading: {
    marginBottom: '20px',
  },
}));

const rows = [
  {
    durationMin: 0,
    durationSec: 0,
    paceMin: 0,
    paceSec: 0,
    repeat: 1,
  },
];

export default function App() {
  const localUnit = JSON.parse(localStorage.getItem('unit')) || 1;

  let [unit, setUnit] = useState(localUnit);

  const checkState = localUnit === 1 ? true : false;
  const [state, setState] = useState({
    checkedB: checkState,
  });

  const handleChange = (event) => {
    if (event.target.checked) {
      setUnit(1);
      localStorage.setItem('unit', 1);
    } else {
      setUnit(0.621371);
      localStorage.setItem('unit', 0.621371);
    }
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const classes = useStyles();
  const [rowData, setRowData] = useState({ rows, totalKM: 5 });

  const addRow = () => {
    rowData.rows.push({
      durationMin: 0,
      durationSec: 0,
      paceMin: 0,
      paceSec: 0,
      repeat: 1,
    });

    const newRows = [...rowData.rows];

    setRowData({ rows: newRows, totalKM: rowData.totalKM });
  };

  const deleteRow = (index) => {
    if (index > -1) {
      rowData.rows.splice(index, 1);
    }
    const newRows = [...rowData.rows];
    setRowData({ rows: newRows, totalKM: rowData.totalKM });
    updateTotal();
  };

  const handleDurationMinChange = (index, event) => {
    if (event.key === 'Enter') {
    }
    rowData.rows[index].durationMin = Number(event);
    if (rowData.rows[index].durationMin >= 0) {
      const newRows = [...rowData.rows];
      setRowData({ rows: newRows, totalKM: rowData.totalKM });
      updateTotal();
    }
  };
  const handleDurationSecChange = (index, event) => {
    const duraSecondsTime = Number(event);

    if (duraSecondsTime >= 0 && duraSecondsTime < 60) {
      rowData.rows[index].durationSec = duraSecondsTime;
      const newRows = [...rowData.rows];
      setRowData({ rows: newRows, totalKM: rowData.totalKM });
      updateTotal();
    }
  };
  const handlePaceMinChange = (index, event) => {
    if (event.key === 'Enter') {
    }
    rowData.rows[index].paceMin = Number(event);
    if (rowData.rows[index].paceMin >= 0 && rowData.rows[index].paceMin < 60) {
      const newRows = [...rowData.rows];
      setRowData({ rows: newRows, totalKM: rowData.totalKM });
      updateTotal();
    }
  };
  const handlePaceSecChange = (index, event) => {
    if (event.key === 'Enter') {
    }
    rowData.rows[index].paceSec = Number(event);
    const newRows = [...rowData.rows];
    if (rowData.rows[index].paceSec >= 0 && rowData.rows[index].paceSec < 60) {
      setRowData({ rows: newRows, totalKM: rowData.totalKM });
      updateTotal();
    }
  };
  const copyRow = (index) => {
    rowData.rows.push({
      durationMin: rowData.rows[index].durationMin,
      durationSec: rowData.rows[index].durationSec,
      paceMin: rowData.rows[index].paceMin,
      paceSec: rowData.rows[index].paceSec,
      repeat: rowData.rows[index].repeat,
    });

    const newRows = [...rowData.rows];

    setRowData({ rows: newRows, totalKM: rowData.totalKM });
    updateTotal();
  };

  const updateTotal = () => {
    let distance = '';

    rowData.rows.map((data, index) => {
      if (
        (data.durationMin === 0 && data.durationSec === 0) ||
        (data.paceMin === 0 && data.paceSec === 0)
      ) {
        return 0;
      }
      const durationseconds = data.durationMin * 60 + data.durationSec;
      const paceseconds = data.paceMin * 60 + data.paceSec;
      distance =
        Number(distance) +
        Number((durationseconds / paceseconds) * data.repeat);
      return distance;
    });

    rowData.totalKM = Math.round(distance * 100) / 100;
    const newRows = [...rowData.rows];
    if (!isNaN(rowData.totalKM)) {
      setRowData({ rows: newRows, totalKM: rowData.totalKM });
    }
  };

  const counterIncrease = (index) => {
    rowData.rows[index].repeat++;

    const newRows = [...rowData.rows];
    setRowData({
      rows: newRows,
      totalKM: rowData.totalKM,
    });
    updateTotal();
  };
  const counterDecrease = (index) => {
    rowData.rows[index].repeat = rowData.rows[index].repeat - 1;

    const newRows = [...rowData.rows];
    setRowData({
      rows: newRows,
      totalKM: rowData.totalKM,
    });
    updateTotal();
  };

  const HandleKeyPress = (event, index, reference) => {
    let newReference = '';
    if (event.charCode === 13) {
      switch (reference) {
        case 'durMin':
          newReference = 'durSec';
          break;
        case 'durSec':
          newReference = 'paceMin';
          break;

        case 'paceMin':
          newReference = 'paceSec';
          break;
        case 'paceSec':
          newReference = 'index';
          break;
        default:
          newReference = 'durMin';
          break;
      }
      if (newReference === 'index') {
        document.getElementById(`paceSec${index}`).blur();
      } else {
        document.getElementById(`${newReference}${index}`).focus();
      }
    }
  };

  useEffect(() => {
    updateTotal();
  }, []);

  return (
    <Box
      justify="center"
      display="flex"
      style={{ height: '100%', flexDirection: 'column' }}
    >
      <Box
        style={{
          flexGrow: 0,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box style={{ flexGrow: 1, margin: '10px' }}>
          <Typography variant="h6" m={2}>
            Pace Calculator
          </Typography>
        </Box>
        <Box
          style={{ display: 'flex', fontSize: '10px', alignItems: 'center' }}
        >
          <Box style={{ width: '20px' }}>{state.checkedB ? null : `Miles`}</Box>
          <IOSSwitch
            checked={state.checkedB}
            size="small"
            onChange={handleChange}
            name="checkedB"
          />
          <Box style={{ width: '20px' }}>{state.checkedB ? `KMs` : null}</Box>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
          <Button
            onClick={() => addRow()}
            variant="contained"
            size="small"
            style={{
              backgroundColor: green[500],
              color: '#fff',
            }}
            className={classes.addButton}
          >
            <AddIcon />
            <Typography>Row</Typography>
          </Button>
        </Box>
      </Box>
      <Box style={{ flexGrow: 12 }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="device table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  size="small"
                  padding="checkbox"
                  style={{ background: grey[800] }}
                >
                  Duration
                </StyledTableCell>
                <StyledTableCell
                  size="small"
                  padding="checkbox"
                  style={{ background: grey[800] }}
                >
                  Pace&nbsp;&nbsp;&nbsp;&nbsp;
                </StyledTableCell>
                <StyledTableCell
                  size="small"
                  padding="checkbox"
                  style={{ background: grey[800] }}
                >
                  Repeat
                </StyledTableCell>
                <StyledTableCell
                  size="small"
                  padding="checkbox"
                  style={{ background: grey[800] }}
                ></StyledTableCell>
                <StyledTableCell
                  size="small"
                  padding="checkbox"
                  style={{ background: grey[800], textAlign: 'center' }}
                >
                  Dist
                </StyledTableCell>
                <StyledTableCell
                  size="small"
                  padding="checkbox"
                  style={{ background: grey[800] }}
                ></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.rows && rowData.rows.length
                ? rowData.rows.map((data, index) => (
                    <StyledTableRow key={`dwrapper${index}`}>
                      <StyledTableCell size="small" padding="checkbox">
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              placeholder="min"
                              label="min"
                              style={{ textAlign: 'center' }}
                              inputProps={{ inputMode: 'numeric' }}
                              value={data.durationMin}
                              onKeyPress={(e) =>
                                HandleKeyPress(e, index, 'durMin')
                              }
                              id={`durMin${index}`}
                              onChange={(event) =>
                                handleDurationMinChange(
                                  index,
                                  event.target.value
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              placeholder="sec"
                              label="sec"
                              style={{ textAlign: 'center' }}
                              inputProps={{ inputMode: 'numeric' }}
                              value={data.durationSec}
                              onKeyPress={(e) =>
                                HandleKeyPress(e, index, 'durSec')
                              }
                              id={`durSec${index}`}
                              onChange={(event) =>
                                handleDurationSecChange(
                                  index,
                                  event.target.value
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </StyledTableCell>
                      <StyledTableCell size="small" padding="checkbox">
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              placeholder="min"
                              label="min"
                              style={{ textAlign: 'center' }}
                              inputProps={{ inputMode: 'numeric' }}
                              onKeyPress={(e) =>
                                HandleKeyPress(e, index, 'paceMin')
                              }
                              value={data.paceMin}
                              id={`paceMin${index}`}
                              onChange={(event) =>
                                handlePaceMinChange(index, event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              placeholder="sec"
                              label="sec"
                              style={{ textAlign: 'center' }}
                              inputProps={{ inputMode: 'numeric' }}
                              onKeyPress={(e) =>
                                HandleKeyPress(e, index, 'paceSec')
                              }
                              value={data.paceSec}
                              id={`paceSec${index}`}
                              onChange={(event) =>
                                handlePaceSecChange(index, event.target.value)
                              }
                            />
                          </Grid>
                        </Grid>
                      </StyledTableCell>
                      <StyledTableCell size="small" padding="checkbox">
                        <ButtonGroup
                          size="small"
                          aria-label="small outlined button group"
                        >
                          {rowData.rows[index].repeat <= 1 ? (
                            <Button disabled size="small">
                              -
                            </Button>
                          ) : (
                            <Button
                              size="small"
                              onClick={() => {
                                counterDecrease(index);
                              }}
                            >
                              -
                            </Button>
                          )}
                          <Button
                            size="small"
                            onClick={() => {
                              counterIncrease(index);
                            }}
                          >
                            +
                          </Button>
                        </ButtonGroup>
                        <Button disabled>{data.repeat}X</Button>
                      </StyledTableCell>
                      <StyledTableCell
                        padding="checkbox"
                        style={{ textAlign: 'center' }}
                      >
                        <IconButton
                          variant="outlined"
                          style={{ padding: 0, color: green[500] }}
                          aria-label="delete"
                          onClick={() => copyRow(index)}
                        >
                          <FileCopyIcon fontSize="small" />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell
                        size="small"
                        padding="checkbox"
                        style={{ textAlign: 'center' }}
                      >
                        {data.durationMin * 60 + data.durationSec > 0 &&
                        data.paceMin * 60 + data.paceSec > 0 &&
                        data.repeat !== 0
                          ? (
                              ((((data.durationMin * 60 + data.durationSec) /
                                (data.paceMin * 60 + data.paceSec)) *
                                data.repeat *
                                100) /
                                100) *
                              unit
                            ).toFixed(2)
                          : 0}
                        {` `}
                        {unit === 1 ? `KM` : `M`}
                      </StyledTableCell>
                      <StyledTableCell
                        padding="checkbox"
                        style={{ textAlign: 'center' }}
                      >
                        <IconButton
                          variant="outlined"
                          aria-label="delete"
                          style={{ padding: 0, color: red[500] }}
                          onClick={() => deleteRow(index)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        xs={12}
        style={{
          flexGrow: 1,
          position: 'fixed',
          bottom: 0,
          left: 0,
          fontSize: 16,
          width: '100%',
          background: blue[400],
          color: '#fff',
          padding: '10px',
          alignItems: 'center',
          verticalAlign: 'middle',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        Total Distance: {(rowData.totalKM * unit).toFixed(2)}{' '}
        {unit === 1 ? `KM` : `M`}
      </Box>
    </Box>
  );
}
